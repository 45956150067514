<template>
  <div class="d-flex align-items-center min-vh-100 login-container">
    <b-container>
      <b-row class="justify-content-center">
        <c-col md="9" lg="6" class="login">
          <b-card class="p-4 card">
            <div class="text-center mb-4">
              <img src="/logo.png" alt />
              <hr class="my-4" />
            </div>
            <b-form v-on:submit.prevent="checkForm">
              <div class="text-center mb-4">
                <h1>AZURE AUTH CALLBACK</h1>
              </div>
              <b-row>
                <c-col class="text-center">
                  <b-button
                    variant="primary"
                    type="button"
                    class="px-4 login-btn"
                    to="/welcome"
                    >GO TO LOGIN PAGE</b-button
                  >
                </c-col>
              </b-row>
            </b-form>
          </b-card>
        </c-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "AzureAuthLogin",
  data() {
    return {
      user: {},
      accessToken: "",
      graphDetails: {},
    };
  },
  methods: {
    resData(accessToken, user, graphDetails) {
      this.accessToken = accessToken;
      this.user = user;
      this.graphDetails = graphDetails;
    },
  },
};
</script>

<style scoped>
.login-container {
  background: #F47306;
}
.login-box {
  background-color: #F47306;
}
.card {
  width: 100%;
  background-color: #ffffff;
  border: transparent solid 1px;
  /* border-top-left-radius: 20%; */
  /* border-bottom-right-radius: 20%; */
  box-shadow: 0px 4px 15px -3px #7b7b7b;
}

h1 {
  color: #F47306;
}
.login-btn {
  padding: 8px !important;
  font-size: 15px;
  border-radius: 10px !important;
  background: #ffffff;
  border: #F47306 solid 1px;
  color: #F47306;
  text-align: center;
  width: 50%;
  margin: auto;
  transition: 0.3s;
}
.login {
  max-width: 500px;
}
.login-btn:hover, .login-btn.disabled, .login-btn:disabled {
    background: #80C141;
    color: #ffffff;
    border: #80C141 solid 1px;
}
@media (max-width: 600px) {
  .card-body {
    padding: 1.25rem 0;
  }
  .login-btn {
    width: 100% !important;
  }
}
</style>
